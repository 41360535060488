import { lazy } from "react";
import Loadable from "../../components/Loadings/Loadable";

const MainLayout = Loadable(lazy(() => import('../../components/Layouts/MainLayout')));

// Auths
const SignInPage = Loadable(lazy(() => import('../../screens/auths/SignIn')));
const HomePage = Loadable(lazy(() => import('../../screens/mains/dashboard/Home')));

// Customer
const FormPage = Loadable(lazy(() => import('../../screens/mains/customer/form')));
const ListCustomerPage = Loadable(lazy(() => import('../../screens/mains/customer/list')));
const BusinessSalesReportPage = Loadable(lazy(() => import('../../screens/mains/customer/analytics')));

// Account
const AccountFormPage = Loadable(lazy(() => import('../../screens/mains/account/form')));
const ListAccountPage = Loadable(lazy(() => import('../../screens/mains/account')));

// Nas
const ListNasPage = Loadable(lazy(() => import('../../screens/mains/nas')));
const FormNasPage = Loadable(lazy(() => import('../../screens/mains/nas/form')));

// Product
const ListProductBillingPage = Loadable(lazy(() => import('../../screens/mains/product')));
const FormProductBillingPage = Loadable(lazy(() => import('../../screens/mains/product/form')));

// Product
const ListCustomertBillingPage = Loadable(lazy(() => import('../../screens/mains/customer-billing')));
const FormCustomerBillingPage = Loadable(lazy(() => import('../../screens/mains/customer-billing/form')));

const appRoutes = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { path: '', element: <HomePage /> },
        ],
    },
    {
        path: '/nas',
        element: <MainLayout />,
        children: [
            { path: '', element: <ListNasPage /> },
            { path: 'form', element: <FormNasPage /> },
        ],
    },
    {
        path: '/products',
        element: <MainLayout />,
        children: [
            { path: '', element: <ListProductBillingPage /> },
            { path: 'form', element: <FormProductBillingPage /> },
        ],
    },
    {
        path: '/customer-products',
        element: <MainLayout />,
        children: [
            { path: '', element: <ListCustomertBillingPage /> },
            { path: 'form', element: <FormCustomerBillingPage /> },
        ],
    },
    {
        path: '/customers',
        element: <MainLayout />,
        children: [
            { path: '', element: <ListCustomerPage /> },
            { path: 'form', element: <FormPage /> },
            { path: 'analytics', element: <BusinessSalesReportPage /> },
        ],
    },
    {
        path: '/accounts',
        element: <MainLayout />,
        children: [
            { path: '', element: <ListAccountPage /> },
            { path: 'form', element: <AccountFormPage /> },
        ],
    },
    {
        path: 'auths',
        children: [
            { path: 'sign-in', element: <SignInPage /> },
        ],
    }
];

export default appRoutes;